import { render, staticRenderFns } from "./paymentDocument.vue?vue&type=template&id=547dde79&scoped=true&"
import script from "./paymentDocument.vue?vue&type=script&lang=js&"
export * from "./paymentDocument.vue?vue&type=script&lang=js&"
import style0 from "./paymentDocument.vue?vue&type=style&index=0&id=547dde79&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "547dde79",
  null
  
)

export default component.exports